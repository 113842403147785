export const porfolioData = [
  {
    src: "./img/portfolio-1.jpg",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minima architecto debitis fuga ea eligendi totam dolorem. Sunt tempore repudiandae dolores! ",
  },
  {
    src: "./img/portfolio-2.jpg",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minima architecto debitis fuga ea eligendi totam dolorem. Sunt tempore repudiandae dolores! ",
  },
  {
    src: "./img/portfolio-3.jpg",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minima architecto debitis fuga ea eligendi totam dolorem. Sunt tempore repudiandae dolores! ",
  },
  {
    src: "./img/portfolio-4.jpg",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minima architecto debitis fuga ea eligendi totam dolorem. Sunt tempore repudiandae dolores! ",
  },
  {
    src: "./img/portfolio-5.jpg",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minima architecto debitis fuga ea eligendi totam dolorem. Sunt tempore repudiandae dolores! ",
  },
  {
    src: "./img/portfolio-6.jpg",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minima architecto debitis fuga ea eligendi totam dolorem. Sunt tempore repudiandae dolores! ",
  },
  {
    src: "./img/portfolio-7.jpg",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minima architecto debitis fuga ea eligendi totam dolorem. Sunt tempore repudiandae dolores! ",
  },
  {
    src: "./img/portfolio-8.jpg",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minima architecto debitis fuga ea eligendi totam dolorem. Sunt tempore repudiandae dolores! ",
  },
];

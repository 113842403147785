import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./HeroSection.css";

function HeroSection() {
  return (
    <section id="hero" style={{ backgroundImage: `url("./img/cover.jpg")` }}>
      <div className="the-inner">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <h1 className="display-2">H&M ZIPPERS</h1>
              <h6 className="display-4">We Deliver the Best</h6>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12} lg={12}>
              <p>
                We deliver premium quality zipper rolls ranging from 3#, 5# and
                10# from black to multicolour. Customers are making use of our
                products in luggage, jackets, pants, trousers, kid wears and
                many more. Our brand provides you with a variety of shades from
                which you can choose with a promise for colour durability with
                highly competitive price. We engage actively with our customers
                to understand their need better and deliver. We not only provide
                the best quality products, we also guarantee customer
                satisfaction.
              </p>
            </Col>
          </Row>

          {/* <Row className="points">
            <Col xs={12} sm={6} md={{ span: 3, offset: 0.5 }} lg={3}>
              <p>Mid Night</p>
            </Col>
          </Row> */}
        </Container>
      </div>
    </section>
  );
}

export default HeroSection;

import React from "react";
import { Alert } from "react-bootstrap";
import "./Alert.css";

function AlertSuccess({ successAlert, setSuccessAlert }) {
  if (successAlert) {
    return (
      <Alert
        variant="success"
        dismissible
        onClose={() => setSuccessAlert(false)}
      >
        <Alert.Heading>Your query is submitted!!!</Alert.Heading>
        <p>Thank you for contacting us. We will revert back ASAP!!!</p>
      </Alert>
    );
  }

  return null;
}

export default AlertSuccess;

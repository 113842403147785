import React, { useState } from "react";
import AlertSuccess from "./../Alert/AlertSuccess";
import AlertFailed from "./../Alert/AlertFailed";

import { Container, Row, Col, Form, Button } from "react-bootstrap";
// import ReactBootstrapStyle from "@bit/react-bootstrap.react-bootstrap.internal.style-links";

import "./ContactSection.css";

function ContactSection() {
  const [data, setData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });

  const [successAlert, setSuccessAlert] = useState(false);
  const [failedAlert, setFailedAlert] = useState(false);

  const changeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    fetch("https://api.apispreadsheets.com/data/13479/", {
      method: "POST",
      body: JSON.stringify({
        data: data,
      }),
    }).then((res) => {
      if (res.status === 201) {
        setSuccessAlert(true);

        setTimeout(() => {
          setSuccessAlert(false);
        }, 5000);
      } else {
        setFailedAlert(true);

        setTimeout(() => {
          setFailedAlert(false);
        }, 5000);
      }
    });
  };

  return (
    <section id="contact">
      <Container>
        <Row className="justify-content-md-center">
          <Col md={7}>
            <h1 className="display-4">Contact Us</h1>
            <hr className="bg-dark" />
            <p className="pb-0 mb-0">
              Just get in contact with us. We are happy to answer your
              questions.
            </p>

            <p className="text-danger small pt-0 mt-0">
              * All fields are required
            </p>

            <Form onSubmit={submitHandler}>
              <Form.Group as={Row} controlId="formHorizontalName">
                <Form.Label column md={4}>
                  Name
                </Form.Label>
                <Col md={8}>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={data["name"]}
                    onChange={changeHandler}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column md={4}>
                  Email
                </Form.Label>
                <Col md={8}>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={data["email"]}
                    onChange={changeHandler}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalSubject">
                <Form.Label column md={4}>
                  Subject
                </Form.Label>
                <Col md={8}>
                  <Form.Control
                    type="text"
                    placeholder="Subject"
                    name="subject"
                    value={data["subject"]}
                    onChange={changeHandler}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalSubject">
                <Form.Label column md={4}>
                  Mobile Number
                </Form.Label>
                <Col md={8}>
                  <Form.Control
                    type="text"
                    placeholder="Your Mobile Number"
                    name="phone"
                    value={data["phone"]}
                    onChange={changeHandler}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalMessage">
                <Form.Label column md={4}>
                  Message
                </Form.Label>
                <Col md={8}>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    name="message"
                    value={data["message"]}
                    onChange={changeHandler}
                  />
                </Col>
              </Form.Group>

              <div className="d-flex justify-content-center pt-3 mt-3">
                <Button type="submit" className="bg-dark btn-lg">
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>

      <AlertSuccess
        successAlert={successAlert}
        setSuccessAlert={setSuccessAlert}
      ></AlertSuccess>

      <AlertFailed
        failedAlert={failedAlert}
        setFailedAlert={setFailedAlert}
      ></AlertFailed>
    </section>
  );
}

export default ContactSection;

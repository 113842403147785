import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./FooterSection.css";

function FooterSection() {
  return (
    <footer style={{ backgroundColor: "black", color: "white" }}>
      <Container>
        <Row className="justify-content-md-center">
          {/* Column 1 */}
          <Col>
            <h3 className="text-center">Company Address</h3>
            <p className="text-center">
              Samaypur Industrail Area, Badli,
              <br />
              New Delhi - 110042
              <br />
              India
            </p>
          </Col>

          {/* Column 2 */}
          <Col>
            <h3 className="text-center">Email</h3>
            <p className="text-center">
              rahmat.khan1234@gmail.com
              <br />
              rahmat.khan1234@hmzippers.com
              <br />
            </p>
          </Col>

          {/* Column 3 */}
          <Col>
            <h3 className="text-center">Phone number</h3>
            <p className="text-center">
              +91-9953832777
              <br />
              +91-9810925177
              <br />
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default FooterSection;

import React from "react";
import { Alert } from "react-bootstrap";
import "./Alert.css";

function AlertFailed({ failedAlert, setFailedAlert }) {
  if (failedAlert) {
    return (
      <Alert variant="danger" >
        <Alert.Heading>Sorry!!!</Alert.Heading>
        <p>We are unable to save your query. Please try again</p>
      </Alert>
    );
  }

  return null;
}

export default AlertFailed;

import React from "react";
import { porfolioData } from "./../../Utils/portfolioData";

import "./PortfolioSection.css";

function PortfolioSection() {
  return (
    <section id="portfolio">
      {/* Section Heading */}
      <div className="flex-container">
        <div style={{ width: "90vw" }}>
          <h1 className="display-4">Products & Use Cases</h1>
          <p>
            Our zippers go through all type of strength testing to make ensure
            we deliver high quality zippers and our customer can use the zippers
            in various products. Our customers use our zippers in clothes (eg.
            pants, trousers, kid wear and many more), luggage and other bags,
            sporting goods, camping gear (e.g. tents and sleeping bags), and
            other items.
          </p>
        </div>
      </div>

      {/* Portfolio images...  */}
      <div className="flex-container">
        {porfolioData.map((portfolio, i) => {
          return (
            <figure key={i}>
              <img
                src={portfolio.src}
                alt=""
                style={{
                  width: "350px",
                  height: "350px",
                  verticalAlign: "top",
                }}
              />

              <div className="overlay">
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Hic
                  molestias obcaecati ea aspernatur fugit, nobis architecto
                  veniam quaerat saepe repudiandae.
                </p>
              </div>
            </figure>
          );
        })}
      </div>
    </section>
  );
}

export default PortfolioSection;

import HeroSection from "./Components/HeroSection/HeroSection";
import PortfolioSection from "./Components/PortfolioSection/PortfolioSection";
import ContactSection from "./Components/ContactSection/ContactSection";
import FooterSection from "./Components/FooterSection/FooterSection";

function App() {
  return (
    <div>
      <HeroSection></HeroSection>
      <PortfolioSection></PortfolioSection>
      <ContactSection></ContactSection>
      <FooterSection></FooterSection>
    </div>
  );
}

export default App;
